export enum RolUsuario {
  Administrador = 'administrador',
  ResponsableLaboratorio = 'responsable-laboratorio',
  AdministradorLaboratorio = 'administrador-laboratorio',
}

export enum EstadoTicket {
  Abierto = 'open',
  Cerrado = 'closed',
}

export enum TipoTicket {
  Administrativo = 'administrativo',
  SoporteBioquimico = 'soporte-bioquimico',
  SoporteTecnico = 'soporte-tecnico',
}

export enum EstadoType {
  Activo = 'enabled',
  Inactivo = 'disabled',
}

export enum VisibilidadType {
  Visible = 'visible',
  Oculto = 'hidden',
}

export enum IdiomaType {
  Espaniol = 'es',
  Ingles = 'en',
}

export enum EsquemaType {
  Tradicional = 1,
  C24,
  RangoFijo,
}

export enum CalculoValorObjetivoType {
  MediaEspecifica,
  MediaPeriodoTiempo,
  RangoFijo,
}

export enum CalculoStdDevType {
  StdDevEspecifica,
  StdDevPeriodoTiempo,
  CVEspecifico,
  CVPeriodoTiempo,
  StdDevPooled,
  CVPromedioPonderado,
}

export enum EstadoBotellaReactivoType {
  NoDisponible,
  EnUso,
  StandBy,
}

export enum EstadoResultadoType {
  NoEvaluado,
  Aceptado,
  Rechazado,
}

export enum EstadoIncidenteType {
  Abierto = 1,
  Cerrado,
}

export enum EstadoNCType {
  EnProceso = 1,
  AccionInmediataCompleta,
  AccionCorrectivaCompleta,
  VerificacionImplementacionCompleta,
  Cerrada,
}

export enum EstadoSectorIncidentesType {
  Ok = 'ok',
  Danger = 'danger',
}

export enum IncidenteType {
  CC_RechazoReglaControl = 'Rechazo regla control',
  CC_EventoControlIncompleto = 'Evento de control incompleto',
  SCI_SigmaLowerThan3 = 'Métrica Sigma',
  SCI_SesgoAbsPercentHigherOrEqualThanESa = 'Error sistemático',
  SCI_CvMensualHigherOrEqualThanEAa = 'Error aleatorio',
  SCE_DesvPercentAbsHigherOrEqualThanETa = 'Error de medida',
  SCE_ZscoreAbsHigherOrEqualThan3 = "Z o Z' inaceptable",
  SCE_ZscoreAbsHigherOrEqualThan2 = "Z o Z' alarma",
  SEM_EP15A3_ExperimentoRechazado = 'EP15A3 rechazado',
  SEM_EP15A3_ExperimentoRevisionDirector = 'EP15A3 revisión por dirección',
  SEM_EP15A3_ExperimentoWithSigmaLowerThan3 = 'EP15A3',
  SEM_EP15A3_Precision_ExperimentoRechazado = 'EP15A3 - Precisión rechazado',
  SEM_EP15A3_Precision_ExperimentoRevisionDirector = 'EP15A3 - Precisión revisión por dirección',
  SEM_EP15A3_Precision_ExperimentoWithSigmaLowerThan3 = 'EP15A3 - Precisión',
}
