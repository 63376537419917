import {FC, useContext, useEffect} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {useSelector, shallowEqual} from 'react-redux'
import {UserModel} from '../../models/UserModel'
import {RootState} from '../../../setup'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {CreateTicketDTO} from '../../models/DTOs/ticket/CreateTicketDTO'
import {showError, showWarning, toastSuccess} from '../../helpers/AlertHelper'
import {formikFieldHasError, KTSVG} from '../../helpers'
import {useIntl} from 'react-intl'
import {errorMessageBuilder} from '../../helpers/error-message-builder'
import ModalContext from './ModelContext'
import {useCreateTicketMutation} from '../../modules/tickets/create-ticket'
import {TipoTicket} from '../../models/DTOs/common/types'

export const ON_TICKET_SUBMIT = 'onTicketSubmit'

const NuevoTicket: FC = () => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const {isOpen, toggleModal} = useContext(ModalContext)

  const intl = useIntl()

  const {mutate: createTicket, isSuccess, isError, error, isLoading} = useCreateTicketMutation()

  const newTicketSchema = Yup.object().shape({
    txtAsunto: Yup.string()
      .min(5, intl.formatMessage({id: 'FORMIK.MIN'}).replace('{number}', '5'))
      .max(50, intl.formatMessage({id: 'FORMIK.MAX'}).replace('{number}', '50'))
      .required(intl.formatMessage({id: 'FORMIK.REQUIRED'})),
    txtMensaje: Yup.string()
      .min(10, intl.formatMessage({id: 'FORMIK.MIN'}).replace('{number}', '10'))
      .max(5000, intl.formatMessage({id: 'FORMIK.MAX'}).replace('{number}', '5000'))
      .required(intl.formatMessage({id: 'FORMIK.REQUIRED'})),
    cbDepto: Yup.string().required(intl.formatMessage({id: 'FORMIK.REQUIRED'})),
  })

  const initialValues = {
    txtAsunto: '',
    txtMensaje: '',
    cbDepto: '',
  }

  const formik = useFormik({
    initialValues,
    validationSchema: newTicketSchema,
    onSubmit: (values) => {
      setTimeout(() => {
        showWarning(
          intl.formatMessage({id: 'WARNING.CONFIRM.TICKET'}),
          intl.formatMessage({id: 'WARNING.CONFIRM.TICKET.MESSAGE'})
        ).then((r) => {
          if (!r.isConfirmed) return

          const nuevoTicket: CreateTicketDTO = {
            Titulo: values.txtAsunto,
            Mensaje: values.txtMensaje,
            Tipo: values.cbDepto,
          }

          createTicket({ticket: nuevoTicket})
        })
      }, 1000)
    },
  })

  useEffect(() => {
    if (!isSuccess && !isError) return

    if (isSuccess) {
      dispatchEvent(new Event(ON_TICKET_SUBMIT))
      toggleModal()
      toastSuccess(intl.formatMessage({id: 'SUCCESS.SEND.TICKET'}))
      formik.resetForm()
    } else if (isError) {
      showError(
        intl.formatMessage({id: 'ERROR.SENDING.TICKET'}),
        errorMessageBuilder(error, intl.formatMessage),
        intl.formatMessage({id: 'HEADER.ACCEPT'})
      )
    }
  }, [isSuccess, isError])

  const btnCerrarFormHandler = () => {
    toggleModal()
    formik.resetForm()
  }

  return (
    <>
      <Modal show={isOpen} size='lg'>
        <Modal.Header>
          <Modal.Title>{intl.formatMessage({id: 'MENU.NEW_TICKET'})}</Modal.Title>
        </Modal.Header>
        <form onSubmit={formik.handleSubmit} noValidate>
          <Modal.Body>
            <div className='alert alert-primary d-flex align-items-center p-5 mb-3'>
              <span className='svg-icon svg-icon-2hx svg-icon-primary me-4 mb-5 mb-sm-0'>
                <KTSVG path='/media/icons/duotune/general/gen044.svg' />
              </span>
              <span>{intl.formatMessage({id: 'INFO.TICKET'})}</span>
            </div>
            <div className='mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'HEADER.SUBJECT'})}</label>
              <input
                placeholder={intl.formatMessage({id: 'PLACEHOLDER.TICKET_SUBJECT'})}
                type='text'
                {...formik.getFieldProps('txtAsunto')}
                autoComplete='off'
                className={`form-control ${formikFieldHasError(formik, 'txtAsunto')}`}
              />
            </div>
            <div className='mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'HEADER.DEPARTMENT'})}</label>
              <select
                {...formik.getFieldProps('cbDepto')}
                className={`form-select ${formikFieldHasError(formik, 'cbDepto')}`}
              >
                <option hidden value={0}>
                  {intl.formatMessage({id: 'PLACEHOLDER.TICKET_DEPARTMENT'})}
                </option>
                <option value={TipoTicket.Administrativo}>
                  {intl.formatMessage({id: 'DEPARTMENT.ADMIN'})}
                </option>
                <option value={TipoTicket.SoporteTecnico}>
                  {intl.formatMessage({id: 'DEPARTMENT.SUPPORT_TEC'})}
                </option>
                <option value={TipoTicket.SoporteBioquimico}>
                  {intl.formatMessage({id: 'DEPARTMENT.SUPPORT_BIO'})}
                </option>
              </select>
            </div>
            <div className='mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'HEADER.MESSAGE'})}</label>
              <textarea
                placeholder={intl.formatMessage({id: 'PLACEHOLDER.TICKET_MESSAGE'})}
                autoComplete='off'
                {...formik.getFieldProps('txtMensaje')}
                className={`form-control ${formikFieldHasError(formik, 'txtMensaje')}`}
                style={{height: '150px'}}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='light-secondary' name='btnCerrarForm' onClick={btnCerrarFormHandler}>
              {intl.formatMessage({id: 'SETTINGS.CLOSE'})}
            </Button>
            <Button
              variant='primary'
              name='btnAceptarForm'
              type='submit'
              disabled={
                formik.isSubmitting ||
                !formik.isValid ||
                isLoading ||
                formik.getFieldProps('cbDepto').value === ''
              }
            >
              {isLoading
                ? intl.formatMessage({id: 'SETTINGS.LOADING'})
                : intl.formatMessage({id: 'SETTINGS.ACCEPT'})}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

export default NuevoTicket
