import {useIntl} from 'react-intl'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()

  return (
    <>
      <AsideMenuItem
        id='pagPrincipal'
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        id='incidentes'
        to='/incidents'
        icon='/media/icons/duotune/general/gen034.svg'
        title={intl.formatMessage({id: 'HEADER.INCIDENTS'})}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        id='noConformidades'
        to='/nonconformities'
        icon='/media/icons/duotune/general/gen034.svg'
        title={intl.formatMessage({id: 'HEADER.NONCONFORMITIES'})}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        id='tableroControl'
        to='/controlpanel'
        icon='/media/icons/duotune/general/gen034.svg'
        title={intl.formatMessage({id: 'HEADER.CONTROLPANEL'})}
        fontIcon='bi-app-indicator'
      />
    </>
  )
}
