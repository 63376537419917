import {FC, useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import {Error404} from '../errors/Error404'
import {setLanguageTemp} from '../../../_metronic/i18n/Metronici18n'
import {setTempStorage} from '../../helpers/StorageHelper'

const GetExternalComponent: FC = () => {
  const search = useLocation().search
  const params = new URLSearchParams(search)

  const action = params.get('action')
  const accessToken = params.get('token')
  const lang = params.get('lang')

  useEffect(() => {
    if (!lang || !accessToken) return
    setLanguageTemp(lang)
    setTempStorage(accessToken)
  }, [])

  if (!accessToken || !lang) return <Error404 />

  switch (action) {
    case 'REPORTE_PDF':
      const type = params.get('type')

      switch (type) {
        default:
          return <Error404 />
      }

    default:
      return <Error404 />
  }
}

export default GetExternalComponent
