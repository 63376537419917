import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {FC} from 'react'
import CardButton from '../../components/UI/card-button/CardButton'
import {useHistory} from 'react-router-dom'

const DashboardWrapper: FC = () => {
  const intl = useIntl()

  let history = useHistory()

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: process.env.REACT_APP_NAME})}</PageTitle>
    </>
  )
}

export {DashboardWrapper}
