import {Suspense, lazy} from 'react'
import {useSelector, shallowEqual} from 'react-redux'
import {Redirect, Route, Switch} from 'react-router-dom'
import {RootState} from '../../setup'
import {FallbackView} from '../../_metronic/partials'
import {UserModel} from '../models/UserModel'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {RolUsuario} from '../models/DTOs/common/types'
import {Logout} from '../pages/auth'

export function PrivateRoutes() {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  const AccountPage = lazy(() => import('../pages/account/AccountPage'))
  const Tickets = lazy(() => import('../pages/tickets/Tickets'))
  const Incidentes = lazy(() => import('../pages/incidentes/Incidentes'))
  const NoConformidades = lazy(() => import('../pages/no-conformidades/NoConformidades'))
  const TableroControl = lazy(() => import('../pages/tablero-control/TableroControl'))

  const isAdmin = user.Rol.Name === RolUsuario.AdministradorLaboratorio

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard' component={DashboardWrapper} />
        <Route path='/account' component={AccountPage} />
        <Route path='/support/tickets' component={Tickets} />
        <Route path='/incidents' exact component={Incidentes} />
        <Route path='/nonconformities' exact component={NoConformidades} />
        <Route path='/controlpanel' exact component={TableroControl} />
        <Route path='/logout' component={Logout} />

        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
